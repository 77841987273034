<template>
  <div class="content-full">
      <div class="grid" style="width: 100%;">
          <div class="col-12 md:col-6 lg:col-6 xl:col-4 card-padding" v-for="(option, idx) in options" :key="option.id ?? idx">
            <inner-card  class="clarity-card-inner m-3 flex" :class="[idx % 2 === 0 ? 'clarity-gradient-blue' : 'clarity-gradient-light-blue', idx === 0 ? 'common-choice' : '']">
              <template #title>
                <div style="position: relative;">
                  <img v-if="resolveImg(option.label)" class="smh-image" :src="resolveImg(option.label)"/>
                </div>
              </template>
              <template #content>
                <div v-if="idx === 0 && mode !== 'liability'">
                  <div class="flex flex-column card-container green-container" >
                    <div class="flex card-title"><h3 class="white">{{ option.label }}</h3></div>
                    <div class="flex-grow-1">
                      <p class="body white">Please select an option:</p>
                      <Dropdown v-model="selectedChoice" :options="commonChoices" optionLabel="label" option-value="id"
                                style="width: 100%" @change="updateCommonChoiceSelection"/>
                    </div>
                    <div class="btn-wrapper">
                      <Button class="clarity-btn clarity-gradient-orange" @click="confirmCommonSelection">Choose</Button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="flex flex-column card-container green-container" >
                    <div class="flex card-title"><h3 class="white">{{ option.label }}</h3></div>
                    <div class="flex-grow-1 hide-small">
                      <p class="body white">{{ option.description }}</p>
                    </div>
                    <div class="btn-wrapper xs:hide">
                      <Button class="clarity-btn clarity-gradient-orange" @click="makeSelection(option)">Choose</Button>
                    </div>
                  </div>
                </div>
              </template>
            </inner-card>
          </div>
      </div>
      <div class="flex mt-5">
        <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/investments/selfmanaged');"><span
            class="material-icons-round mr-3">chevron_left</span>Go Back
        </Button>
      </div>
  </div>
</template>

<script>
import {computed, onMounted, ref } from 'vue';
import {useStore} from "vuex";
import InnerCard from "@/components/common/InnerCard";
import router from "@/router";

export default {
  name: "StepOneChooseType",
  components: {InnerCard},
  setup() {
    const store = useStore()
    const mode = computed(() => store.state.selfManaged.mode)
    const productTypes = computed(() => {
        if(mode.value === 'liability') {
            return store.getters.liabilityTypes
        }else{
            return store.getters.productTypes
        }
    })
    const commonChoices = computed(() => store.getters.commonChoices)
    const selectedChoice = ref(4)
    const options = computed(() => {
        const optionValue = [{
            label: 'Most Common',
            description: 'Le Dropdown',
        }]
        if(mode.value === 'liability'){
            optionValue.pop()
        }
        productTypes.value.forEach(type => {
            optionValue.push(type)
        })
        return optionValue;
    })

    onMounted(() => {
      store.dispatch('resetStepper', ['selfManagedOptions'])
      store.dispatch('resetSelfManagedChoices')
      if (options.value.length === 1) {
        store.dispatch('fetchProductTypes')
        store.dispatch('fetchLiabilityTypes')
      }
    })


    const makeCommonChoiceSelection = () => {
      store.dispatch('setNewSelfManagedType', store.getters.getParentOfProduct(selectedChoice.value).id)
      store.dispatch('updateSelectedProduct', selectedChoice.value)
    }

    const updateCommonChoiceSelection = (event) => {
        console.log('set to ', event.value)
        selectedChoice.value = event.value
    }

    const confirmCommonSelection = () => {
      store.dispatch('setNewSelfManagedType', store.getters.getParentOfProduct(selectedChoice.value).id)
      store.dispatch('updateSelectedProduct', selectedChoice.value)
      store.dispatch('setSelectedStep', {stepper: 'selfManagedOptions', step: 2, completed: 1})
      router.push('/investments/selfmanaged/add/details')
    }

    const confirmSelection = () => {
      store.dispatch('setActiveModal', {modal: 'selectSelfManagedSubProduct'});
    }

    const makeSelection = (selection) => {
      store.dispatch('setNewSelfManagedType', selection.id)
      store.dispatch('updateSelectedProduct', selection.products[0].id)
      confirmSelection()
    }

    const resolveImg = (label) => {
      switch (true) {
        case label === 'Most Common':
            return null
        case label === 'Investment':
          return require('@/assets/img/selfManagedHoldings/Sticky-Investment.png')
        case label === 'Pension':
          return require('@/assets/img/selfManagedHoldings/Sticky-Pension.png')
        case label === 'Protection':
          return require('@/assets/img/selfManagedHoldings/Sticky-Protection.png')
        case label === 'Property':
          return require('@/assets/img/selfManagedHoldings/Sticky-Property.png')
        case label === 'Cash':
          return require('@/assets/img/selfManagedHoldings/Sticky-Cash.png')
        case label === 'Liability':
            return require('@/assets/img/selfManagedHoldings/Sticky-Investment.png')
      }
    }

    return {
      mode,
      options,
      commonChoices,
      selectedChoice,
      makeSelection,
      makeCommonChoiceSelection,
      confirmCommonSelection,
      confirmSelection,
      resolveImg,
      updateCommonChoiceSelection
    }
  }
}
</script>

<style scoped>
.content-full {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
}

.clarity-card-inner {
  width: 100%;
  height: 100%;
}

.card-title {
  margin-top: 150px;
    z-index: 4;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.btn-wrapper {
  margin-top: 30px;
}

.clarity-btn {
  padding: 15px 45px;
  width: 100%;
  justify-content: center;
}
.smh-image {
  height: 137px;
  z-index: 2;
   position: absolute;top: 0px;right: 0px; margin-top: 30px; margin-right: 30px;
}
.card-padding {
    padding: 10px;
}

.card-container{
    min-height: 400px;
}

@media only screen and (max-width: 768px) {
    .hide-small {
        display: none;
    }
    .card-container{
        min-height: 200px;
    }
    .smh-image {
        height: 137px;
        z-index: 2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}


</style>
